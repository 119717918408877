<template>
  <b-modal
    @shown="updateItem"
    title="編輯下載檔案"
    id="editAnnouceFile"
    size="lg"
  >
    <b-container fluid>
      <main class="main-grid-dtc">
        <b-input-group prepend="標題名稱" style="grid-column: 1/-1;">
          <b-input v-model="item.Subject"></b-input>
        </b-input-group>
        <b-input-group prepend="檔案說明" style="grid-column: 1/-1;">
          <b-input v-model="item.Content"></b-input>
        </b-input-group>
        <b-input-group prepend="開始時間" style="grid-column: 1/-1;">
          <b-form-datepicker
            v-model="item.StartDate"
            placeholder="選擇開始時間"
          ></b-form-datepicker>
        </b-input-group>
        <b-input-group prepend="截止時間" style="grid-column: 1/-1;">
          <b-form-datepicker
            v-model="item.EndDate"
            placeholder="選擇截止時間"
          ></b-form-datepicker>
        </b-input-group>
        <b-input-group prepend="上傳檔案" style="grid-column: 1/-1;">
          <div class="custom-file-upload-out">
            <label for="file-upload" class="custom-file-upload">
              <span style="color: rgb(105 36 136);">
                <i class="fas fa-cloud-upload-alt fa-lg"></i>
              </span>
              <div>Upload File</div>
              <input id="file-upload" type="file" @change="fileChange" />
            </label>
          </div>
          <div style="padding-top:13px;padding-left:8px">{{ fileName }}</div>
        </b-input-group>
        <b-input-group prepend="備註說明" style="grid-column: 1/-1;">
          <b-input v-model="item.Remark"></b-input>
        </b-input-group>
      </main>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="warning"
          class="float-right mr-3"
          @click="$bvModal.hide('editAnnouceFile')"
          >取消</b-button
        >
        <b-button
          variant="primary"
          class="float-right mr-3"
          @click="uploadNewFile"
          >確定</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import moment from "moment";
import { store } from "@/store/global.js";
export default {
  name: "DownloadAreaAddNew",
  data() {
    return {
      subject: "",
      content: "",
      startTime: "",
      endTime: "",
      file: null,
      item: {},
      remark: "",
      reswllInfo: "",
      fileName: "",
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
    };
  },
  computed: {},
  methods: {
    updateItem() {
      this.reswllInfo = `${window.sessionStorage.docrkkt}`.replace(/\"/g, "");
      this.item = { ...store.downloadEditItem };
      this.item.StartDate = Boolean(this.item.StartDate)
        ? moment(this.item.StartDate).format("YYYY-MM-DD")
        : "";
      this.item.EndDate = Boolean(this.item.EndDate)
        ? moment(this.item.EndDate).format("YYYY-MM-DD")
        : "";
      let fileNameLength = this.item.FileLocation.split("/").length - 1;
      this.fileName = this.item.FileLocation.split("/")[fileNameLength];
    },
    fileChange(e) {
      this.fileName = e.target.files[0].name;
      this.file = e.target.files[0];
    },
    async uploadNewFile() {
      //check endTime

      // let endTimeTomorrow = Date.parse(
      //   `${moment(this.item.EndDate)
      //     .add(1, "days")
      //     .format("YYYY-MM-DD")}`
      // );

      // if (endTimeTomorrow < Date.now()) {
      //   this.$bvToast.toast("請選擇有效的截止時間", {
      //     title: "編輯失敗!!",
      //     autoHideDelay: 5000,
      //     variant: "danger",
      //   });
      //   return;
      // }

      let form = new FormData();

      form.append("Id", this.item.Id);
      if (Boolean(this.file)) {
        form.append("input", this.file);
      }

      form.append("Subject", "" + this.item.Subject);
      form.append("Content", "" + this.item.Content);
      form.append("StartDate", "" + this.item.StartDate + " 00:00:00");
      // form.append("StartDate", "" + this.item.StartDate);
      form.append("EndDate", "" + this.item.EndDate + " 23:59:59");
      form.append("Remark", "" + this.item.Remark);
      form.append(
        "Createtime",
        "" + moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
      );
      // form.append("Creater", "" + "admin");
      form.append("Creater", "" + this.reswllInfo);

      try {
        const map = await window.axios.post(
          "FileManagment/DocUploadFile",
          form
        );
        this.$bvToast.toast(`編輯成功!!`, {
          title: "下載檔案",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.$root.$emit("get-Newest-DownloadAreaData");
        this.$root.$emit("get-Newest-DownloadAreaManageData");
        this.$bvModal.hide("editAnnouceFile");
      } catch (e) {
        this.$bvToast.toast("編輯失敗!! " + e, {
          title: "下載檔案",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}
.code-dtc {
  display: grid;
  grid-template-columns: 60px minmax(400px, 525px);
}
.input-group {
  margin-bottom: 10px;
}
.main-grid-dtc {
  display: grid;
  grid-template-columns: 350px 1fr;
}
.main-grid3-dtc {
  display: grid;
  grid-template-columns: 350px 350px 1fr;
}

.custom-file-upload-out {
  border: 1px solid #ccc;
  padding: 6px 12px;
  .custom-file-upload {
    display: inline-block;
    padding-top: 6px;
    cursor: pointer;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;

    input[type="file"] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
}
::v-deep #editAnnouceFile > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.form-control[readonly] {
  background-color: #ffffff;
}
</style>
